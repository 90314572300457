import {
  TeliaRow,
  TeliaHeading,
  TeliaLink,
  TeliaP,
} from '@teliads/components/react';
import { PageProps } from 'gatsby';
import React from 'react';
import ContentfulRichText from '../components/ContentfulRichText';
import { Grid, Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { useStaticTranslations } from '../hooks/useStaticTranslations';
import {
  ContentfulGuideEntity,
  ContentfulStaticTranslations,
} from '../models/ContentfulTypes';

interface Props extends PageProps {
  pageContext: {
    entity: ContentfulGuideEntity;
    stepNumber: number;
    steps: { entity: ContentfulGuideEntity; url: string }[];
    nextPageUrl: string;
    totalAmountOfSteps: number;
    stepBeginning: number;
    contentfulId: string;
    arr: string[];
    deviceSlug: string;
    language: string;
  };
}
const PathSelector = ({ pageContext }: Props) => {
  const { entity, contentfulId, stepNumber, arr, deviceSlug, language } =
    pageContext;
  const translation = useStaticTranslations();
  const stepTranslation = translation[language].nodes?.find(
    node => node.contentful_id === ContentfulStaticTranslations.step
  );

  return (
    <Layout
      contentfulId={contentfulId}
      deviceSlug={deviceSlug}
      title={entity.title}
    >
      <Grid>
        <TeliaRow className="padding--vertical column">
          <span className="telia-heading--step">
            {stepTranslation?.title} {stepNumber}
          </span>
          <TeliaHeading variant="display-100" tag="h2">
            {entity.title}
          </TeliaHeading>
        </TeliaRow>
        <TeliaRow className="column gap center padding--vertical">
          {entity.entityElements?.map((element, index) => {
            const href = arr[index] ? arr[index] : '';
            return (
              <TeliaLink href={href} className="step__item" key={index} silent>
                <article>
                  <div className="step__item--title">
                    <TeliaHeading tag={'h4'} variant={'title-100'}>
                      {element.title || 'No Title'}
                    </TeliaHeading>
                    {element.description?.raw && (
                      <ContentfulRichText raw={element.description.raw} />
                    )}
                  </div>
                  <div className="step__item--footer">
                    {element.animation?.file?.url ? (
                      <video width="320" height="240" loop>
                        <source
                          src={element.animation?.file?.url}
                          type="video/mp4"
                        />
                        <img src={element.mediaAsset?.file?.url} alt="" />
                      </video>
                    ) : (
                      <img src={element.mediaAsset?.file?.url} alt="" />
                    )}
                  </div>
                </article>
              </TeliaLink>
            );
          })}
        </TeliaRow>
      </Grid>
    </Layout>
  );
};

export default PathSelector;
